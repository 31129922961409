import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import twStyles from './Video.tw-styles'
import { getSrcW } from '../../adapters/cloudinary.adapters'

const Video = ({ videoSrc, posterImageURL, controls }) => {
  const [videoLoaded, setVideoLoaded] = useState(false)

  useEffect(() => {
    setVideoLoaded(false)
  }, [videoSrc])

  const handlePlay = () => {
    setVideoLoaded(true)
  }

  return (
    <video
      loop
      muted
      autoPlay
      playsInline
      disablePictureInPicture
      className={twStyles.video.videoWrapper}
      poster={getSrcW(posterImageURL)}
      controls={controls && videoLoaded}
      key={videoSrc}
      onPlay={handlePlay}
      data-testid='videoTag'
    >
      <source src={videoSrc} type='video/mp4' />
      <track kind='captions' srcLang='en' label='captions' />
    </video>
  )
}

Video.propTypes = {
  videoSrc: PropTypes.string.isRequired,
  posterImageURL: PropTypes.string.isRequired,
  controls: PropTypes.bool,
}
Video.defaultProps = {
  controls: true,
}

export default Video
