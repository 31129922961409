module.exports = {
  topSection: {
    backgroundWrapper: 'background-wrapper top-70 md:top-90 w-full absolute',
    pageTitle: 'page-title relative text-center text-white pt-25 lg:pt-30 pb-20 md:w-7/12 w-11/12 mx-auto font-montserratSemiBold font-semibold  text-30 lg:text-50 leading-wider lg:leading-leading60 ',
  },
  content: {
    mainWrapper: 'mt-10px md:mt-20px xl:mt-17px md:px-20',
  },
  myImpactSection: {
    mainWrapper: 'impactDashboardContainer xl:mx-auto  pt-30 pb-50 lg:pt-50 md:pt-45 lg:py-30 bg-rewardsBg',
  },
  myImpactBanner: {
    bannerSection: 'banner-section container mx-auto mt-0 w-12/12 px-20 pb-10 relative top-0  md:rounded-rewards md:pb-10 ',
    brandsSection: 'container mx-auto mt-12px md:px-20 pb-10 relative top-0 md:pb-15 lg:pb-15 ',
    recommendBrandsSection: 'container mx-auto mt-10px md:mt-20px pb-10 relative top-0 md:pb-05 lg:pb-15 ',
    pointsSpinnerIcon: 'pointsSpinnerIcon flex justify-center py-10 lg:py-20',
    mainWrapper: 'relative w-full',
    wrapper: 'relative overflow-hidden max-h-320 md:max-h-full',
    pointsWrapper: 'absolute top-0 inset-x-0',
    DashBoardbannerWrapper: 'bg-white md:bg-rewardsBg xl:bg-white',
    // DashBoardbannerWrapper: 'DashBoardbannerWrapper pointsSpinnerIcon bg-white px-20
    // text-center pb-45 pt-40 md:pt-50 lg:pt-60 lg:pb-50 md:mx-auto md:px-31 lg:pt-50',
    title: 'text-24 leading-leading29 font-montserratSemiBold font-semibold lg:text-38 lg:leading-noImgLh pb-10 lg:pb-40',
    subTitle: 'text-base leading-lightmedium font-montserratlight font-light pb-25 lg:text-lg w-10/12 md:w-7/12 lg:px-20 xl:px-30 xxl:w-5/12 xl:w-6/12 mx-auto lg:leading-widest lg:pb-30',
    impactPointsLabel: 'impactPointsLabel w-full text-center font-montserratBold font-normal text-purple text-24 xl:text-38 leading-leading42 md:pt-05 xl:pt-0 pb-15 xl:pb-15 xxl:pb-20',
    dashBoardPoints: 'dashBoardPoints h-full flex flex-wrap justify-center items-center pt-30 md:pt-40',
    dashBoardPointsTitle: 'text-60 md:text-80 leading-leading43 font-bold xl:text-100 xl:relative top-25',
    BannerLinkCta: 'BannerLinkCta w-full text-center text-purple underline font-montserratSemiBold font-semibold focus:outline-none text-16 leading-widest lg:flex lg:text-18 lg:leading-widest lg:items-center lg:justify-center',
    BannerEarnPointsCta: 'BannerEarnPointsCta lg:pt-20 lg:pb-17 lg:pl-20',
    goodTrackerHead: 'goodTrackerHead text-lg font-montserratBold font-bold leading-wider text-center md:leading-small lg:text-28 lg:leading-wider',
    goodTrackerHomeHead: 'goodTrackerHead text-24 font-montserratSemiBold font-semibold text-center leading-widest lg:text-32 lg:leading-leading42',
    goodTrackerText: 'goodTrackerText text-base leading-lightmedium font-montserratlight font-light text-center w-9/12 mx-auto pt-08 pb-15 md:pb-05 lg:text-lg lg:leading-widest lg:pt-20',
    goodTrackerHomeText: 'goodTrackerText text-base leading-lightmedium font-montserratlight font-light text-center w-9/12 mx-auto pt-08 pb-15 md:pb-15 lg:text-lg lg:leading-widest',
    userActionWrapper: 'userActionWrapper bg-white lg:pt-35',
    userActionInner: 'userActionInner lg:w-5/12 lg:mx-auto lg:pb-20',
    pointsSvgIcon: 'w-24 h-24 ml-8px',
  },
  brandLogos: {
    brandWrapper: 'mx-auto brandWrapper px-10 md:px-20 md:w-11/12 lg:w-730 lg:px-30 xl:px-20 justify-center flex flex-wrap xl:w-730',
    brandWrapperRec: 'mx-auto px-10 md:px-20 md:w-9/12 justify-center flex flex-wrap xl:w-630 pt-15 pb-60',
    title: 'w-full text-18 xl:text-28 pt-50 leading-wider font-bold flex items-center justify-center',
    subTitle: 'w-11/12 md:w-10/12 lg:w-8/12 xl:w-6/12 xxl:w-5/12 mx-auto text-center pt-10 font-light flex items-center text-16 leading-lightmedium xl:text-18 leading-widest justify-center',
    subTitleRec: 'w-11/12 lg:w-8/12 mx-auto text-center pt-10 font-light flex items-center xl:text-18 leading-lightmedium xl:leading-widest justify-center',
    logoWrapper: 'w-115 h-115 xl:w-127 xl:h-127 logoWrapper',
    brandLogoWrapper: 'relative flex pb-10 xl:pb-18',
    heartIconSVG: 'absolute z-1 cursor-pointer left-0 xl:left-5 xl:left-10 xl:top-2 top-0 w-30 xl:w-22',
    flipIconSVG: 'absolute z-1 cursor-pointer right-15 bottom-15 w-20 h-15',
    logo: 'p-15',
    ctaWrapper: 'pt-35 xl:pt-50 w-full flex flex-wrap justify-center',
    ctaWrapperText: 'pt-20 pb-45 xl:pt-20 xl:pb-65 w-full flex flex-wrap justify-center',
    linkText: 'underline font-semibold text-14 leading-lightmedium',
  },
}

