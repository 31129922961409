export default {
  homeIntroSection: {
    outerWrapper: 'container mx-auto relative',
    mainWrapper:
      'w-full bg-white -top-54 md:top-auto relative md:flex flex-warp mx-auto md:w-9/12',
    mainLogoutWrapper:
      'w-full md:bg-white pt-30 md:pt-0 -top54 md:top-auto relative z-11 md:flex flex-warp mx-auto md:w-9/12',
    mainLoginWrapper:
      'w-full bg-white relative pt-15 md:pt-0 md:flex flex-warp mx-auto md:w-11/12 xl:w-9/12 xxl:w-10/12',
    wrapper:
      'relative hero-intro-banner h-full w-full min-h-200 xl:min-h-375 xxl:min-h-428 overflow-hidden md:bg-primaryHome',
    textContainerWrapper: 'md:w-7/12 lg:w-6/12 relative',
    textWrapper:
      'md:relative bg-transparent relative text-white -top54 mx-10px md:mx-0 text-left md:text-left pt-20 pb-30 px-30 md:pl-30 pr-15 md:pt-15 md:pb-15 md:pl-0 md:pr-10 md:pl-50 xl:min-h-340 xl:pl-70 xxl:pl-73 xl:pr-05 xl:pt-50 xxl:pt-76 flex flex-wrap items-center md:top-30 lg:top-0 xl:top-12 xxl:top-0 Hometextwrapper',
    homeIntroTitle:
      'container mx-auto homeIntroTitle md:text-white w-full font-montserratBold font-bold pb-15 md:pb-10 xl:pb-05 md:pr-25 xl:pr-0 text-28 xl:text-32 leading-contactus xl:leading-leading42',
    homeIntroSubTitle:
      'home-intro-title container w-10/12 xxl:w-9/12 md:text-white font-montserrat font-normal md:font-montserratSemiBold md:pr-20 lg:pr-15 xl:pr-0 xxl:pr-15 md:font-medium text-16 xl:text-18 leading-lightmedium md:leading-small xl:leading-widest',
    homeIntroLoginSubTitle:
      'home-intro-login-subTitle',
    cardText:
      'font-montserratlight font-light text-14 leading-points18 px-10 md:px-12',
    cardWrapper: 'w-9/12 mx-auto text-center flex flex-wrap',
    cardHeader:
      'font-montserratBold font-bold lg:text-18 lg:leading-lightmedium px-08 lg:px-0 pt-10 lg:pt-15',
    cards: {
      wrapper:
        'howItWorksCard w-full block text-center px-10 md:px-0 xl:px-10 relative md:-top-30',
      loggedInWrapper:
        'howItWorksCard w-full block text-center px-10 md:px-0 xl:px-10 relative md:top-35',
      title: 'font-montserratSemiBold font-semibold py-05 xl:text-24',
      subTitle: 'font-montserratlight font-light xl:text-18 px-20',
      collapseCloseBtn:
        'w-full bg-IOHBlue hover:bg-blue md:bg-primary mx-15 flex-wrap relative ml-0 mr-15px top-0 justify-center py-15 md:py-15 text-white text-16 font-montserratBold font-bold leading-ultralight md:top-auto md:mx-auto md:absolute md:bottom-0 flex lg:py-16 xl:leading-lightmedium xl:text-18 ',
      signupBtn:
        'w-full md:bg-white signup-Btn relative -top-54 md:top-auto flex justify-center pt-40 md:pt-0 pb-0 lg:pb-0',
      signupImpactBtn: 'w-full flex justify-center pt-50 pb-10 md:pb-30',
    },
  },
  redeemPointsSection: {
    sectionWrapper: 'bg-bgcolor pt-15 xl:pt-25',
    redeemMainWrapper: 'w-full bg-white lg:pb-0 container mx-auto',
    redeemMainContentWrapper: 'w-full text-center',
    redeemWrapper:
      'w-11/12 md:w-7/12 xl:w-5/12 flex flex-wrap earnPointsRedeem text-center mx-auto ',
    earnWrapper:
      'w-11/12 md:w-7/12 xl:w-6/12 xxl:w-5/12 pt-20 md:pt-0 flex flex-wrap earnPointsRedeem text-center mx-auto ',
    titleWrapper: 'w-full text-center mx-auto pb-24 xl:bg-white',
    titleWrapperRewards: 'w-full text-center mx-auto pb-10',
    titleContent:
      'font-montserratSemiBold font-semibold text-24 leading-widest text-sign-head xl:leading-noImgLh xl:text-32 ',
    subTitleContent:
      'w-full text-16 xl:text-18 text-center xl:bg-white mx-auto pt-05 px-15 md:px-50 xl:px-40 xxl:px-20 font-montserratlight font-light leading-normal lg:leading-widest ',
    wedonateWrapper:
      'relative h-full z-5 w-full xl:w-auto overflow-hidden top-0 left-0',
    image:
      'h-580 w-full  xl:w-auto mx-auto object-cover md:h-439 lg:h-468 xl:h-510',
    contentWrapper:
      'absolute contentWrapper-youearn bottom-5 md:bottom-5 w-full h-full md:h-auto justify-end flex flex-wrap items-center lg:bottom-50',
    cardsContentWrapper:
      'text-center leading-lightmedium text-white text-16 font-montserratSemiBold font-semibold lg:text-18 lg:leading-widest lg:pt-30 lg:pb-25 pt-22 pb-15',
    cardWrapper: 'w-9/12 flex flex-wrap mx-auto',
    cardContent: 'w-full flex md:block pb-30 md:pb-0',
    cardImageContent:
      'flex w-6/12 md:w-full md:block text-center mx-auto redeemPointsArrw justify-center md:min-h-100',
    cardImageContent1:
      'flex w-10/12 md:w-full md:block text-center mx-auto redeemPointsArrw justify-center',
    cardText:
      'w-9/12 flex text-left pt-10 md:w-full md:text-center mx-auto md:px-0 md:pl-10 pr-20 md:pr-0 flex-col ',
    cardText1:
      'w-full flex text-left xl:bg-white pt-10 lg:pt-05 xl:pt-10 md:w-full md:text-center mx-auto md:px-0 pl-0 md:pl-10 pr-10 md:pr-0 flex-col ',
    cardTitle:
      'w-full font-montserratBold font-bold pt-05 text-16 text-sign-head md:text-center leading-lightmedium lg:text-18 lg:leading-lightmedium',
    cardTitlerewards:
      'w-full font-montserratSemiBold font-semibold pt-05 text-16 xl:text-18 text-sign-head md:text-center leading-lightmedium',
    cardDescription:
      'w-full text-sign-head md:bg-white text-16 md:mx-auto md:text-center md:w-full xxl:w-10/12 leading-lightmedium font-montserratlight font-light xxl:px-05 lg:text-18 lg:leading-lightmedium pt-0 md:pt-04',
    bubbleIconCls: 'bubble-mainIcon cursor-pointer',
    bubleIconWrapper: 'w-full flex justify-center',
    bubleIconContent: 'bubble-mainIcon pointer-events-none',
  },
}
