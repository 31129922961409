import {
  homeArrowLightBlue,
  homeArrowGreen,
  homeArrowOrange,
  homeArrowPink,
  homeArrowBlue,
} from '../../constants/icon-names'

const getIconName = (variant) => {
  switch (variant) {
    case 'Primary Blue':
      return homeArrowLightBlue
    case 'Green':
      return homeArrowGreen
    case 'Red':
      return homeArrowOrange
    case 'Magenta':
      return homeArrowPink
    case 'Blue':
      return homeArrowBlue
    default:
      return homeArrowLightBlue
  }
}

export default getIconName
