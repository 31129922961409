import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import Head from 'next/head'
import LazyLoad from 'react-lazyload'
import { useRouter } from 'next/router'
import crypto from 'crypto'
import HomeIntroSection from '../../components/HomeIntroSection/HomeIntroSection'
import RichText from '../../components/RichText/RichText'
import Heading from '../../components/Heading/Heading'
import LinkButton from '../../components/LinkButton/LinkButton'
import Typography from '../../components/Typography/Typography'
import Image from '../../components/Image/Image'
import Carousal from '../../components/Carousal/Carousal'
import Icon from '../../components/Icon/Icon'
import { handleOktaSignup } from '../../helpers/login.helper'
import BubbleIconButton from '../../components/Button/BubbleIconButton'
import { home } from '../../constants/page-source'
import twStyles from './Home.tw-styles'
import homeStyles from './Home.styles'
import styles1 from '../UserManagement/MyAccount/MyAccount.tw-styles'
import Banner from '../../components/Banner/Banner'
import LoginStore from '../../components/UserManagement/Login-store'
import {
  rewardMessageAfterSurveyCompletion,
  isSegmantaLoaded, feedbackSurvey, isSurveyCompleted,
} from '../../helpers/segmanta.helper'
import FeedBack from '../../components/FeedBack/FeedBack'
import { getParameterByName } from '../../helpers/redirect.helpers'
import { loginContextText, NPSSurveyUrl } from '../../constants/texts'
import { getSrcW } from '../../adapters/cloudinary.adapters'
import Video from '../../components/Video/Video'
import {
  triggerHomeBannerInternalLinkEvent,
  triggerHomeBannerViewBrandEvent, triggerHomeEarnButtonEvent,
  triggerHomeSignupEvent, triggerHomeGoodNewsEvent,
  triggerHomeFeedbackEvent,
} from '../../helpers/gaEvent.helper'
import { storageAvailable } from '../../adapters/serverside.adapters'
// import refreshAllTabs from '../../constants/refreshTabs'

const HomepageContainer = ({
  introductionBanner,
  howWeAreHelpingSection,
  brandsSection,
  makeAnImpactBackground,
  impactSection,
  overallTotalImpact,
  youEarnSection,
  seeHowItWorksButton,
  learnMoreButton,
  joinusSection,
  whatsNewSectionCollection,
  feedbackSectionCollection,
  siteSettings,
  bannerLinkForLoggedInUser,
  bannerLinkForLoggedOutUser,
  introductionBannerTextForLoggedInUser,
  bannerVideoLink,
  charityImpactList,
}) => {
  const [impactLoading, setImpactLoading] = useState(true)
  useEffect(() => {
    setImpactLoading(false)
  })
  const getSpinner = () => (
    <div>
      <Icon name='spinnerCoral' className='w-24 h-24 mx-auto' />
    </div>
  )
  let overallTotalCharity = 0
  let TotalCharity = 0
  TotalCharity = parseInt(charityImpactList?.totalCharityCounts, 10)
  overallTotalCharity = TotalCharity || 0

  const router = useRouter()
  LoginStore.articlePendingUrl = router.asPath
  const [introBanner, setIntroBannerVisibility] = useState(true)
  const [Showfeedback, setShowFeedback] = useState(false)
  const [ShowfeedbackSection, setShowFeedbackSection] = useState(true)
  const [showNPSSurvey, setShowNPSSurvey] = useState(false)
  const [OSClassName, setOSClassName] = useState('introductionBanner-container')
  const [videoLoaded, setVideoLoaded] = useState(false)
  // const [refreshFlag, setRefreshFlag] = useState(false)

  const takeFeedbackSurveyToGetPointsHandler = () => {
    isSegmantaLoaded(async () => {
      feedbackSurvey(process.env.DEQ_FEEDBACK_DEC_THINK_TANK, null, true)
    })
  }
  const handleFeedbackSurvey = () => (siteSettings?.takeFeedbackSurveyToGetPoints
    ? takeFeedbackSurveyToGetPointsHandler() : setShowFeedback(true))

  useEffect(() => {
    setVideoLoaded(false)
  }, [])
  useEffect(() => {
    let OSName = 'Unknown OS'
    if (navigator.userAgent.indexOf('Win') !== -1) OSName = 'Windows'
    if (navigator.userAgent.indexOf('Mac') !== -1) OSName = 'MacOS'
    if (navigator.userAgent.indexOf('X11') !== -1) OSName = 'UNIX'
    if (navigator.userAgent.indexOf('Linux') !== -1) OSName = 'Linux'

    if (OSName === 'MacOS') setOSClassName('introductionBanner-container-mac')
  }, [])
  useEffect(() => {
    if (
      storageAvailable('sessionStorage')
      && sessionStorage.getItem('campaignSource') === 'pampers'
      && localStorage.getItem('alreadyRegisteredEmail')
    ) LoginStore.showSignInPopup = true
    if (
      LoginStore.userLoggedIn === false
      && document.referrer.includes('my-account')
    ) {
      LoginStore.showSignInPopup = true
    }
  }, [])
  useEffect(() => {
    if (getParameterByName('utm_campaign') === 'email-feedback-survey') {
      if (!LoginStore.rewardStatus) {
        LoginStore.showSignInPopup = true
        LoginStore.context = loginContextText.featuredPromo
      } else {
        isSegmantaLoaded(async () => {
          const status = await isSurveyCompleted(process.env.DEQ_FEEDBACK_DEC_THINK_TANK)
          if (!status) {
            feedbackSurvey(process.env.DEQ_FEEDBACK_DEC_THINK_TANK)
          }
        })
      }
    }
    if (getParameterByName('utm_campaign') === 'nps-survey') {
      if (!LoginStore.rewardStatus) {
        LoginStore.showSignInPopup = true
        LoginStore.context = loginContextText.featuredPromo
      } else {
        setShowNPSSurvey(true)
        window.history.pushState(null, null, '/')
      }
    }
    if (LoginStore.rewardStatus) {
      setIntroBannerVisibility(false)
      rewardMessageAfterSurveyCompletion(siteSettings)
    }
    if (LoginStore.rewardStatus === true) {
      isSegmantaLoaded(async () => {
        window.SEG_EMBED_API.on('SURVEY_GOAL_REACHED', async (scenarioid) => {
          if (scenarioid) {
            if (scenarioid.toString() === process.env.DEQ_FEEDBACK_DEC_THINK_TANK) {
              setShowFeedbackSection(false)
            }
          }
        })
      })
    }
    if (LoginStore.rewardStatus) {
      isSegmantaLoaded(async () => {
        const status = await isSurveyCompleted(process.env.DEQ_FEEDBACK_DEC_THINK_TANK)
        if (status) {
          setShowFeedbackSection(false)
        }
      })
    }
  }, [LoginStore.rewardStatus, LoginStore.takeASurvey])
  return (
    <>
      <Head>
        {introductionBanner?.image && (
          <link
            rel='preload'
            media='(max-width: 768px) and (min-width: 300px)'
            as='image'
            href={getSrcW(introductionBanner?.image?.smartphoneImage?.url)}
          />
        )}
        {introductionBanner?.image && (
          <link
            rel='preload'
            media='(min-width: 769px)'
            as='image'
            href={getSrcW(introductionBanner?.image?.desktopImage?.url)}
          />
        )}
      </Head>
      {Showfeedback && (
        <FeedBack
          url='https://pgsurveys.segmanta.com/7edfq8'
          iconName='closeGray'
          closeHandler={() => setShowFeedback(false)}
        />
      )}
      {showNPSSurvey && (
        <FeedBack
          url={
            process.env.OKTA_ENVIRONMENT === 'production'
              ? NPSSurveyUrl.prodUrl
              : NPSSurveyUrl.stageUrl
          }
          iconName='closeWhite'
          closeHandler={() => setShowNPSSurvey(false)}
        />
      )}
      <div data-nosnippet>
        <div className='bg-bgcolor'>
          <div
            className={
              LoginStore.userLoggedIn
                ? twStyles.howItWorks.outerWrapper
                : twStyles.homeIntroSection.outerWrapper
            }
          >
            {introductionBanner && (
              <>
                <div
                  className={`${OSClassName} ${twStyles.homeIntroSection.wrapper}`}
                >
                  <div
                    className={`${OSClassName} introductionBanner-container md:flex min-h-310 xl:min-h-375 xxl:min-h-428 `}
                  >
                    <div className='HomeBannerVideo flex md:absolute right-0 inset-y-0 md:w-6/12'>
                      <Video
                        containerClass='w-full dsadasd'
                        autoPlay
                        videoSrc={bannerVideoLink}
                        controls
                        handlePlay={false}
                        videoLoaded={videoLoaded}
                        posterImageURL={
                          introductionBanner?.image?.desktopImage?.url
                        }
                        className=''
                      />
                    </div>
                    <div
                      className={
                        twStyles.homeIntroSection.textContainerWrapper
                      }
                    >
                      <Image
                        altText='Light blue lines making up the bottom 1/4 of a circle.'
                        className='hidden lg:block absolute top-0 '
                        desktopImage={
                          introductionBanner?.iconImages?.smartphoneImage
                        }
                      />
                      <Image
                        altText='Semi-circle made of light blue dots.4'
                        className='hidden lg:block absolute bottom-0 right-10 '
                        desktopImage={
                          introductionBanner?.iconImages?.desktopImage
                        }
                      />
                      <div className={twStyles.homeIntroSection.textWrapper}>
                        {LoginStore.userLoggedIn === false && (
                          <div
                            className={
                              twStyles.homeIntroSection.homeIntroTitle
                            }
                          >
                            <Typography markdown={introductionBanner?.title} />
                          </div>
                        )}
                        <div
                          className={
                            twStyles.homeIntroSection.homeIntroSubTitle
                          }
                        >
                          {LoginStore.userLoggedIn === false && (
                            <Typography
                              markdown={introductionBanner?.subTitle}
                              renderers={{
                                link: ({ href, children }) => (
                                  <button
                                    type='button'
                                    aria-label='Home intro '
                                    style={{
                                      textDecorationLine: 'underline',
                                      fontWeight: '500',
                                    }}
                                    onClick={() => {
                                      if (href === '/brands') router.push(href)
                                    }}
                                  >
                                    {children}
                                  </button>
                                ),
                              }}
                            />
                          )}
                          {LoginStore.userLoggedIn === true && (
                            <div
                              className={
                                twStyles.homeIntroSection.homeIntroLoginSubTitle
                              }
                            >
                              <RichText
                                pageSource={home}
                                variant='home-join-us'
                                {...introductionBannerTextForLoggedInUser}
                                renderers={{
                                  link: ({ href, children }) => (
                                    <button
                                      type='button'
                                      aria-label='Home intro '
                                      style={{
                                        textDecorationLine: 'underline',
                                        fontWeight: '500',
                                      }}
                                      onClick={() => {
                                        if (href === '/brands') router.push(href)
                                      }}
                                    >
                                      {children}
                                    </button>
                                  ),
                                }}
                              />
                            </div>
                          )}
                          <div className='pt-23 md:pt-20'>
                            {LoginStore.userLoggedIn === true && (
                              <LinkButton
                                pageSource={home}
                                className='btn bg-white border-3 border-white hover:bg-transparent text-primaryHome hover:text-white'
                                linkText={bannerLinkForLoggedInUser?.linkText}
                                link={bannerLinkForLoggedInUser?.link}
                              />
                            )}
                            {LoginStore.userLoggedIn === false && (
                              <LinkButton
                                pageSource={home}
                                className='btn bg-white border-3 border-white hover:bg-transparent text-primaryHome hover:text-white'
                                linkText={bannerLinkForLoggedOutUser?.linkText}
                                handler={handleOktaSignup}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {LoginStore.userLoggedIn === undefined && (
                  <Icon
                    name='spinnerWhite'
                    className={twStyles.howItWorks.spinnerCls}
                  />
                )}
                {LoginStore.userLoggedIn === true && !introBanner && (
                  <div className={twStyles.howItWorks.collapseHowItWorksBtn}>
                    <LinkButton
                      {...seeHowItWorksButton}
                      variant='howItSatrtBtn'
                      pageSource={home}
                      handler={() => setIntroBannerVisibility(true)}
                    />
                  </div>
                )}
                {(LoginStore.userLoggedIn === false || introBanner) && (
                  <div
                    className={
                      LoginStore.userLoggedIn
                        ? twStyles.howItWorks.boxBtnMainWrapperLoggedIn
                        : twStyles.howItWorks.boxBtnMainWrapper
                    }
                  >
                    <div
                      className={
                        LoginStore.userLoggedIn
                          ? twStyles.homeIntroSection.mainLoginWrapper
                          : twStyles.homeIntroSection.mainLogoutWrapper
                      }
                    >
                      <HomeIntroSection
                        introductionBanner={introductionBanner}
                        siteSettings={siteSettings}
                      />
                    </div>
                    <div
                      className={
                        LoginStore.userLoggedIn
                          ? twStyles.homeIntroSection.cards.collapseCloseBtn
                          : twStyles.homeIntroSection.cards.signupBtn
                      }
                    >
                      {!LoginStore.userLoggedIn ? (
                        <LinkButton
                          pageSource={home}
                          {...introductionBanner.callToActionsCollection
                            .items[0]}
                          link=''
                          handler={() => {
                            triggerHomeBannerInternalLinkEvent()
                            handleOktaSignup()
                          }}
                        />
                      ) : (
                        <LinkButton
                          {...learnMoreButton}
                          variant='closeBtnAfterLoginBtn'
                          pageSource={home}
                          handler={() => setIntroBannerVisibility(false)}
                        />
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div id='scrolltoText' className={twStyles.helpSection.mainWrapper}>
          <div className={twStyles.helpSection.wrapper}>
            {howWeAreHelpingSection && (
              <Carousal
                {...howWeAreHelpingSection}
                styles='cause-help'
                lazyLoad='true'
                pageSource={home}
                variant='Magenta'
                updateCarouselIndex={() => {}}
              />
            )}
          </div>
        </div>
        <LazyLoad once>
          <div className={twStyles.horizontalBrandsBanner.wrapper}>
            {brandsSection && (
              <Banner
                pageSource={home}
                {...brandsSection}
                handler={() => {
                  triggerHomeBannerViewBrandEvent()
                }}
              />
            )}
          </div>
        </LazyLoad>
        <LazyLoad once>
          <div className={twStyles.redeemPointsSection.sectionWrapper}>
            <div className={twStyles.redeemPointsSection.redeemMainWrapper}>
              <div
                className={
                  twStyles.redeemPointsSection.redeemMainContentWrapper
                }
              >
                {youEarnSection && (
                  <div className={twStyles.redeemPointsSection.wedonateWrapper}>
                    <Image
                      className={twStyles.redeemPointsSection.image}
                      {...youEarnSection.image}
                    />
                    <div
                      className={twStyles.redeemPointsSection.contentWrapper}
                    >
                      <div className={twStyles.redeemPointsSection.earnWrapper}>
                        <div
                          className={twStyles.redeemPointsSection.titleWrapper}
                        >
                          <p
                            className={
                              twStyles.redeemPointsSection.titleContent
                            }
                          >
                            {youEarnSection.title}
                          </p>
                          <div
                            className={
                              twStyles.redeemPointsSection.subTitleContent
                            }
                          >
                            <Typography markdown={youEarnSection.subTitle} />
                          </div>
                        </div>
                        <div className='w-full md:flex flex-warp mx-auto '>
                          {youEarnSection?.cardContentsCollection?.items?.map(
                            (item) => (
                              <>
                                <div
                                  className={
                                    twStyles.redeemPointsSection.cardContent
                                  }
                                >
                                  <div
                                    className={
                                      twStyles.redeemPointsSection
                                        .cardImageContent1
                                    }
                                  >
                                    <div
                                      className={
                                        twStyles.redeemPointsSection
                                          .bubleIconWrapper
                                      }
                                    >
                                      <BubbleIconButton
                                        ariaLabelText={item && item.heading}
                                        name={item && item.iconName}
                                        variant={item && item.variant}
                                        to={item && item.link}
                                        className={
                                          twStyles.redeemPointsSection
                                            .bubleIconContent
                                        }
                                        behavior={item && item.behavior}
                                        surveyName={item && item.surveyName}
                                        featuredSurvey={
                                          siteSettings
                                          && siteSettings.featuredSurvey
                                        }
                                        monthlyPromoRewardId={
                                          siteSettings
                                          && siteSettings.monthlyPromoRewardId
                                        }
                                        mosStartAndEndDate={
                                          siteSettings.mosStartAndEndDate
                                        }
                                      />
                                    </div>
                                    <div
                                      className={
                                        twStyles.redeemPointsSection.cardText1
                                      }
                                    >
                                      <div
                                        className={
                                          twStyles.redeemPointsSection
                                            .cardTitlerewards
                                        }
                                      >
                                        <Typography
                                          markdown={item.heading}
                                          key={item && item.sys && item.sys.id}
                                        />
                                      </div>
                                      <div
                                        className={
                                          twStyles.redeemPointsSection
                                            .cardDescription
                                        }
                                      >
                                        {item.text && (
                                          <Typography
                                            markdown={item.text}
                                            key={crypto
                                              .randomBytes(12)
                                              .toString('hex')}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ),
                          )}
                        </div>
                        <div className='w-full justify-center flex pt-08 md:pt-30 lg:pt-30 pb-20'>
                          <LinkButton
                            pageSource={home}
                            handler={() => triggerHomeEarnButtonEvent()}
                            {...youEarnSection.callToActionsCollection.items[0]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </LazyLoad>
        <LazyLoad once>
          <div className='bg-bgcolor pt-15 xl:pt-25'>
            <div className='w-full bg-white lg:pb-0 container mx-auto'>
              <div className={twStyles.millionActs.iconWrapper}>
                <div className={twStyles.millionActs.icon}>
                  <Image {...overallTotalImpact.image} />
                </div>
                <div className={twStyles.millionActs.iconTitle}>
                  {impactLoading
                    ? getSpinner()
                    : overallTotalCharity.toLocaleString('en-US')}
                </div>
                <div>
                  <div className='w-60 xl:w-150 h-60 xl:h-150 mx-5px xl:block'>
                    <Image {...overallTotalImpact.image} />
                  </div>
                </div>
              </div>
              <div className={twStyles.millionActs.wrapper}>
                <div className={twStyles.millionActs.totalWrapper}>
                  <p className={twStyles.millionActs.totalTitle}>
                    {overallTotalImpact.heading}
                  </p>
                  <p className={twStyles.millionActs.totalSubTitle}>
                    {overallTotalImpact.text}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </LazyLoad>
        {/* <LazyLoad once>
        <div className={twStyles.millionAct.mainWrapper}>
          <div className={twStyles.millionAct.wrapper}>
            <div className={twStyles.millionAct.textMainWrapper}>
              <div className={twStyles.millionAct.textWrapper}>
                <Typography
                  className={twStyles.millionAct.title}
                  markdown={millionActsOfGoodTitle}
                />
                <Typography
                  className={twStyles.millionAct.subTitle}
                  markdown={millionActsOfGoodDescription}
                />
                <div className={twStyles.millionAct.blueBtnWrapper}>
                  {millionActsOfGoodLinkButton && (
                    <LinkButton
                      className={twStyles.millionAct.blueBtn}
                      handler={() => triggerHomeImpactEvent()}
                      {...millionActsOfGoodLinkButton}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className='w-full'>
              <Image
                className='w-full 15millionsGood md:w-auto md:min-w-1260 xl:min-w-1440 xxl:w-full'
                {...millionActsOfGoodImage}
              />
            </div>
          </div>
        </div>
      </LazyLoad> */}
        <LazyLoad once>
          <div className={twStyles.myImpactSection.sectionWrapper}>
            <div className={twStyles.myImpactSection.wrapper}>
              <div className={twStyles.myImpactSection.mainWrapper}>
                <div className={twStyles.myImpactSection.imageWrapper}>
                  <Image
                    className={twStyles.myImpactSection.imageInnerClass}
                    {...makeAnImpactBackground}
                  />
                </div>
                <div className={twStyles.myImpactSection.titleWrapper}>
                  <div className={styles1.myImpactBanner.goodTrackerHomeHead}>
                    <h2>{impactSection.pageTitle}</h2>
                  </div>
                  <div className={styles1.myImpactBanner.goodTrackerHomeText}>
                    <Typography markdown={impactSection.pageDescription} />
                  </div>
                  <div className={twStyles.myImpactSection.carouselWrapper}>
                    <Carousal
                      carouselPanelsCollection={
                        impactSection.selectCause?.carouselPanelsCollection
                      }
                      styles='select-cause'
                      variant={impactSection.slug}
                      pageSource={home}
                      updateCarouselIndex={() => {}}
                    />
                  </div>
                  <div className={twStyles.myImpactSection.signupImpactBtn} />
                  <div>
                    <Typography
                      className={twStyles.myImpactSection.text}
                      markdown={impactSection.footerContent}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LazyLoad>
        <LazyLoad once>
          {!LoginStore.userLoggedIn && (
            <div className='bg-bgcolor  min-h-155 lg:min-h-400 flex'>
              {joinusSection && (
                <Banner
                  {...joinusSection}
                  pageSource={home}
                  handler={() => {
                    triggerHomeSignupEvent()
                    handleOktaSignup()
                  }}
                />
              )}
            </div>
          )}
        </LazyLoad>
        <LazyLoad once>
          <div>
            {whatsNewSectionCollection && (
              <div className={twStyles.homeContainer.sectionWrapper}>
                <div className={twStyles.homeContainer.mainWrapper}>
                  <div className={twStyles.articleCarousel.sliderWrapper}>
                    {whatsNewSectionCollection.items.map((item) => {
                      if (item) {
                        switch (item.__typename) {
                          case 'HeadingTypes':
                            return (
                              <Heading
                                {...item}
                                key={item?.sys?.id}
                                pageSource={home}
                              />
                            )
                          case 'ContentTypeRichText':
                            return (
                              <RichText
                                {...item}
                                key={item?.sys?.id}
                                pageSource={home}
                                variant='home-join-us'
                              />
                            )
                          case 'Carouselcontainer':
                            return (
                              <div>
                                {item && (
                                  <Carousal
                                    {...item}
                                    panelStyle={twStyles.articleCarousel.panel}
                                    pageSource={home}
                                    variant='Article Carousel'
                                    styles='good-news-home'
                                    updateCarouselIndex={() => {}}
                                  />
                                )}
                              </div>
                            )
                          case 'LinkButton':
                            return (
                              <div
                                className={twStyles.feedBackSection.cta}
                                key={item?.sys?.id}
                              >
                                <LinkButton
                                  {...item}
                                  key={item?.sys?.id}
                                  pageSource={home}
                                  helpers={() => triggerHomeGoodNewsEvent()}
                                />
                              </div>
                            )
                          default:
                            break
                        }
                      }
                      return null
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
        </LazyLoad>
        <LazyLoad once>
          <div className='pb-15 lg:pb-25 bg-bgcolor'>
            {feedbackSectionCollection?.items?.length > 0
              && ShowfeedbackSection && (
                <div className={twStyles.feedBackSection.mainWrapper}>
                  <div className={twStyles.feedBackSection.wrapper}>
                    {feedbackSectionCollection?.items?.map((item) => {
                      if (item) {
                        switch (item.__typename) {
                          case 'ContentTypeRichText':
                            return (
                              <RichText
                                {...item}
                                key={item?.sys?.id}
                                pageSource={home}
                              />
                            )
                          case 'LinkButton':
                            return (
                              <div
                                className={twStyles.feedBackSection.cta}
                                key={item?.sys?.id}
                              >
                                <LinkButton
                                  {...item}
                                  handler={() => {
                                    if (item.behaviour === 'feedbackSurvey') {
                                      handleFeedbackSurvey()
                                      triggerHomeFeedbackEvent()
                                    }
                                  }}
                                  key={item?.sys?.id}
                                  pageSource={home}
                                />
                              </div>
                            )
                          default:
                            break
                        }
                      }
                      return null
                    })}
                  </div>
                </div>
            )}
          </div>
        </LazyLoad>
      </div>
      <style jsx global>
        {homeStyles}
      </style>
    </>
  )
}

HomepageContainer.propTypes = {
  introductionBanner: PropTypes.oneOfType([PropTypes.object]).isRequired,
  brandsSection: PropTypes.oneOfType([PropTypes.object]).isRequired,
  impactSection: PropTypes.oneOfType([PropTypes.object]).isRequired,
  overallTotalImpact: PropTypes.instanceOf(Object).isRequired,
  youEarnSection: PropTypes.oneOfType([PropTypes.object]).isRequired,
  joinusSection: PropTypes.oneOfType([PropTypes.object]).isRequired,
  whatsNewSectionCollection: PropTypes.oneOfType([PropTypes.object]).isRequired,
  feedbackSectionCollection: PropTypes.oneOfType([PropTypes.object]).isRequired,
  millionActsOfGoodTitle: PropTypes.string.isRequired,
  millionActsOfGoodDescription: PropTypes.string.isRequired,
  millionActsOfGoodImage: PropTypes.oneOfType([PropTypes.object]).isRequired,
  millionActsOfGoodLinkButton: PropTypes.oneOfType([PropTypes.object])
    .isRequired,
  href: PropTypes.string,
  children: PropTypes.string,
  seeHowItWorksButton: PropTypes.oneOfType([PropTypes.object]).isRequired,
  learnMoreButton: PropTypes.oneOfType([PropTypes.object]).isRequired,
  makeAnImpactBackground: PropTypes.oneOfType([PropTypes.object]).isRequired,
  howWeAreHelpingSection: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isPreview: PropTypes.bool.isRequired,
  fetchImpactApi: PropTypes.oneOfType([PropTypes.object]).isRequired,
  siteSettings: PropTypes.oneOfType([PropTypes.object]),
  bannerLinkForLoggedInUser: PropTypes.oneOfType([PropTypes.object]),
  bannerLinkForLoggedOutUser: PropTypes.oneOfType([PropTypes.object]),
  introductionBannerTextForLoggedInUser: PropTypes.instanceOf(Object).isRequired,
  bannerVideoLink: PropTypes.string.isRequired,
  charityImpactList: PropTypes.oneOfType([PropTypes.object]),
  carouselPanelsCollection: PropTypes.oneOfType([PropTypes.object]),
}

HomepageContainer.defaultProps = {
  href: null,
  children: null,
  siteSettings: null,
  bannerLinkForLoggedInUser: null,
  bannerLinkForLoggedOutUser: null,
  charityImpactList: null,
  carouselPanelsCollection: null,
}

export default observer(HomepageContainer)
