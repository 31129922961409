module.exports = {
  default: {
    default: {},
  },
  Generic: {
    'heading-feature': {
      headinglevel: 'text-30 font-montserratSemiBold font-semibold font-thin  pb-10 lg:text-50 leading-wider lg:leading-leading60',
    },
  },
  about: {
    default: 'w-full text-24 leading-leading29 lg:leading-noImgLh lg:text-38 font-montserratSemiBold font-semibold text-center bg-white px-20 pt-35 md:pt-55 lg:pt-65 pb-20 md:pb-23',
    'about-getInvolved': 'w-11/12 md:w-full mx-auto text-30 md:text-26 leading-wider md:leading-sitemap32 lg:leading-noImgLh lg:text-38 font-montserratSemiBold font-semibold text-center px-30 pt-45 md:pt-45 lg:pt-60 pb-20 text-white',
  },
  coupons: {
    default: 'w-full text-24 leading-leading29 lg:leading-leading42 lg:text-32 font-montserratSemiBold font-semibold text-center pb-23',
    'faq-heading': 'w-11/12 md:w-full text-24 leading-leading29 lg:leading-leading42 lg:text-32 text-couponsBlue font-bold text-center pb-40 xl:pb-60',
  },
  couponsDigital: {
    default: 'w-full text-24 leading-leading29 lg:leading-leading42 lg:text-32 font-montserratSemiBold font-semibold text-center pb-23',
    'faq-heading': 'w-full bg-white text-couponsBlue text-30 leading-wider lg:leading-leading42 lg:text-32 font-bold text-center py-40',
  },
  search: {
    default: 'font-montserratSemiBold font-semibold text-16 xl:text-16 leading-leading28 pb-10',
    'search-checkout': 'font-montserratSemiBold font-semibold text-16 leading-leading28 xl:text-16 w-full pt-25 pb-10 xl:pb-14 md:pt-30',
  },
  Home: {
    default: 'text-sign-head w-full text-center text-24 font-montserratSemiBold font-semibold px-10 leading-wider pb-05 lg:text-32 lg:leading-noImgLh pt-20 md:pt-0 lg:pb-20 lg:pt-20',
  },
  causeDetail: {
    'heading-csdw-communities': 'CSDW heading-csdw-communities font-montserratSemiBold font-semibold text-24 leading-leading29 lg:text-38 lg:leading-leading42',
    'heading-united-way': 'united heading-united pt-20 lg:pt-25 font-montserratSemiBold font-semibold text-24 leading-leading29 lg:text-38 lg:leading-leading42',
    'heading-clean-home': 'united heading-united pt-20 pb-08 xl:pb-16 lg:pt-25 font-montserratSemiBold font-semibold text-24 leading-leading29 lg:text-38 lg:leading-leading42',
    'heading-clean-commit': 'pt-28 md:pt-35 pb-08 xl:pb-16 lg:pt-40 font-montserratSemiBold font-semibold text-24 leading-leading29 lg:text-38 lg:leading-leading42',
    'pampers-cause-title-noTop-icon': 'united heading-united pt-25 md:pt-35 lg:pt-45 font-montserratSemiBold font-semibold text-24 leading-leading29 lg:text-38 lg:leading-leading42',

  },
  articleDetail: {
    default: 'w-full article-subheading text-18 lg:text-21 font-montserratSemiBold font-semibold pt-05 pb-15 md:pt-20 md:pb-15 leading-wider lg:leading-normal',
    'articel-details-subheading': 'w-full article-subheading text-18 lg:text-21 font-montserratSemiBold font-semibold pt-05 pb-15 md:pt-20 md:pb-15 leading-wider lg:leading-normal',
    'articel-details-subheading-recycle': 'w-full article-subheading text-18 lg:text-21 font-montserratSemiBold font-semibold pt-10 pb-01 md:pt-25 md:pb-01 leading-wider lg:leading-normal',
    'article-details-subheading-RecycleTitle': 'w-full article-subheading text-22 xl:text-28 text-center font-montserratSemiBold font-semibold py-15 md:pb-20 md:pt-25 leading-small lg:leading-wider',
    'article-details-subheading-title': 'w-full article-subheading text-22 font-montserratSemiBold font-semibold py-10 md:pb-20 md:pt-05 leading-small lg:leading-normal',
    'articel-details-subheading-h2': 'w-full article-subheading text-18 lg:text-21 font-montserratSemiBold font-semibold pt-05 pb-0 md:pt-10 leading-wider lg:leading-normal',
    'articel-details-subheading-plastic': 'w-full article-subheading text-18 lg:text-21 font-montserratSemiBold font-semibold pt-40 pb-0 md:pt-40 leading-wider lg:leading-normal',
    'articel-details-subheading-relief': 'w-full article-subheading text-18 lg:text-21 font-montserratSemiBold font-semibold pt-55 mt-35px border-t-half leading-wider lg:leading-normal',
  },
  'about-sub-ourgoals': 'text-center text-22 lg:text-30 w-full mx-auto leading-small lg:leading-wider py-10 md:ml-4/12 font-montserratSemiBold font-semibold font-light text-sign-head',

}
