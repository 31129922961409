import React from 'react'
import PropTypes from 'prop-types'
import { getPage } from '../adapters/contentful.adapters'
import layoutQuery from '../graphql/queries/layoutQuery'
import homePageQuery from '../containers/Home/HomeQuery'
import HomePageContainer from '../containers/Home/Home'
import Layout from '../components/Layout/Layout'
import MetaData from '../components/MetaData/MetaData'

const HomePage = ({
  layoutData, page, isPreview, fetchImpactApi,
}) => (
  <Layout {...layoutData}>
    <MetaData {...page.pageMetaData} />
    <HomePageContainer
      {...page}
      {...layoutData}
      isPreview={isPreview}
      fetchImpactApi={fetchImpactApi}
    />
  </Layout>
)

HomePage.propTypes = {
  layoutData: PropTypes.instanceOf(Object).isRequired,
  oktaLabels: PropTypes.instanceOf(Object),
  page: PropTypes.instanceOf(Object).isRequired,
  isPreview: PropTypes.bool.isRequired,
  fetchImpactApi: PropTypes.instanceOf(Object).isRequired,
}
HomePage.defaultProps = {
  oktaLabels: null,
}

export const getStaticProps = async ({ preview }) => {
  const page = await getPage('homePage40Collection', homePageQuery)
  const layoutData = await getPage('layoutCollection', layoutQuery)
  // const fetchImpactApi = await fetchImpact()
  return {
    props: {
      page,
      layoutData,
      isPreview: !!preview,
      // fetchImpactApi,
    },
  }
}

export default HomePage
