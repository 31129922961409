import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { handleOktaSignup } from '../../helpers/login.helper'
import getColor from '../Icon/icon.helper'
import Icon from '../Icon/Icon'
import Button from './index'
import getIconName from './BubbleIcon.helper'
import getSurveyId from '../../containers/SurveyLanding/Survey.helper'
import { feedbackSurvey, getMasterSurveyID } from '../../helpers/segmanta.helper'
import LoginStore from '../UserManagement/Login-store'
import Image from '../Image/Image'
import { triggerHomeBannerIconEvent } from '../../helpers/gaEvent.helper'

const BubbleIconButton = (props) => {
  const {
    to,
    name,
    target,
    icon,
    variant,
    className,
    surveyName,
    behavior,
    ariaLabelText,
    featuredSurvey,
    monthlyPromoRewardId,
    image,
    arrowColorVariant,
    mosStartAndEndDate,
  } = props

  let surveyID = ''
  const getID = async () => {
    surveyID = await getMasterSurveyID(featuredSurvey, monthlyPromoRewardId, mosStartAndEndDate)
  }
  if (surveyName && surveyName.surveyName) {
    if (
      surveyName.surveyName === 'DEQ_MASTER'
      && LoginStore.rewardStatus
    ) getID()
    else surveyID = getSurveyId(surveyName.surveyName)
  }

  const handleBubbleIconClick = (event) => {
    if (behavior === 'Take_Survey') {
      triggerHomeBannerIconEvent(event?.currentTarget?.href)
      feedbackSurvey(surveyID)
      return
    }
    if (behavior === 'You Engage') {
      handleOktaSignup()
    }
  }

  return (
    <div>
      <Button
        aria-label={ariaLabelText}
        to={to}
        target={target}
        className='flex flex-col p-02 relative items-center extra-perks'
        handler={handleBubbleIconClick}
      >
        {name && <Icon className={className} name={name} />}
        {image && <Image className={className} {...image} />}
        <Icon
          name={arrowColorVariant ? 'arrowHome' : icon || getIconName(variant)}
          className='w-35 h-35 bubble-icon'
          spanStyle='icon-btn'
          color={arrowColorVariant && getColor(arrowColorVariant)}
        />
      </Button>
      <style global='true'>
        {`{
                    .icon-btn{
                        position: relative;
                        top: -4vh;
                    }
                }`}
      </style>
    </div>
  )
}

BubbleIconButton.propTypes = {
  to: PropTypes.string,
  name: PropTypes.string.isRequired,
  target: PropTypes.string,
  icon: PropTypes.string,
  variant: PropTypes.string,
  className: PropTypes.string,
  surveyName: PropTypes.instanceOf(Object),
  behavior: PropTypes.string,
  ariaLabelText: PropTypes.string,
  featuredSurvey: PropTypes.bool,
  monthlyPromoRewardId: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object]),
  arrowColorVariant: PropTypes.string,
  mosStartAndEndDate: PropTypes.instanceOf(Object),
}

BubbleIconButton.defaultProps = {
  target: null,
  variant: null,
  className: '',
  icon: null,
  to: null,
  surveyName: null,
  behavior: null,
  ariaLabelText: null,
  featuredSurvey: false,
  monthlyPromoRewardId: '',
  image: null,
  arrowColorVariant: null,
  mosStartAndEndDate: null,
}
export default observer(BubbleIconButton)
