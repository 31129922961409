import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'next/router'
import LoginStore from '../UserManagement/Login-store'
import twStyles from './HomeIntroSection.tw-styles'
import BubbleIconButton from '../Button/BubbleIconButton'
import Typography from '../Typography/Typography'
import HomeIntroSectionStyles from './HomeIntroSection.styles'

function HomeIntroSection(props) {
  const { introductionBanner, siteSettings } = props
  return (
    <div className='md:flex flex-warp'>
      {introductionBanner?.cardContentsCollection?.items?.map((item) => (
        <>
          <div
            className={
              LoginStore.userLoggedIn
                ? twStyles.homeIntroSection.cards.loggedInWrapper
                : twStyles.homeIntroSection.cards.wrapper
            }
          >
            <div className={twStyles.redeemPointsSection.cardContent}>
              <div className={twStyles.redeemPointsSection.cardImageContent}>
                <BubbleIconButton
                  ariaLabelText={item?.heading}
                  name={item?.iconName}
                  variant={item?.variant}
                  to={item?.link}
                  className={twStyles.redeemPointsSection.bubbleIconCls}
                  mosStartAndEndDate={siteSettings.mosStartAndEndDate}
                />
              </div>
              <div className={twStyles.redeemPointsSection.cardText}>
                <div className={twStyles.redeemPointsSection.cardTitle}>
                  <Typography markdown={item.heading} key={item?.sys?.id} />
                </div>
                <div className={twStyles.redeemPointsSection.cardDescription}>
                  {item.text && (
                    <Typography
                      markdown={item.text}
                      //   key= {}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ))}
      <style jsx global>
        {HomeIntroSectionStyles}
      </style>
    </div>
  )
}

HomeIntroSection.propTypes = {
  introductionBanner: PropTypes.oneOfType([PropTypes.object]).isRequired,
  siteSettings: PropTypes.oneOfType([PropTypes.object]),
}

HomeIntroSection.defaultProps = {
  siteSettings: null,
}

export default withRouter(HomeIntroSection)
