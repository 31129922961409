import React from 'react'
import PropTypes from 'prop-types'
import Typography from '../Typography/Typography'
import twStyles from './Heading.tw-styles'

const Heading = ({
  heading, pageSource, variant,
}) => {
  const pageStyle = twStyles[pageSource] || twStyles.default
  const style = pageStyle[variant] || pageStyle.default

  return (
    <>
      <Typography className={`${style}`} markdown={heading} />
    </>
  )
}

Heading.propTypes = {
  heading: PropTypes.string.isRequired,
  pageSource: PropTypes.string,
  variant: PropTypes.string,
}

Heading.defaultProps = {
  pageSource: null,
  variant: null,
}

export default Heading
